<template>
  <div class="c-app"> 
    <div v-if="!has_raffles">{{no_raffles_label}}</div>
    <CWrapper v-if="raffle">
      <TheHeader :c1="raffle.color_1" :c2="raffle.color_2" @anchor="anchor"/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid class="p-0">
            <CRow id="pagos" ref="pagos" class="home-section-one">
              <CCol class="home-title" sm="12" :style='"background-color: "+raffle.color_2'>
                <h3>INFORMACIÓN DE PAGO</h3>
              </CCol>
            </CRow>
            <CRow class="home-section-one">
              <CCol sm="4"></CCol>
              <CCol class="home-subtitle" sm="4" :style='"color: "+raffle.color_2'>
                <br />
                <h3>El pago deberá ser realizado a alguna de las siguientes cuentas y reportado al</h3>
                <a href="https://wa.me/+523751461635" target="_blank"><img src="/whatsapp.jpg" style="width: 100%" alt=""></a>
                <br /><br />
                <span style="color: red">Para depósitos y transferencias en el concepto deberás ingresar el nombre con el que estás participando en la rifa.</span>
                <br /><br />
              </CCol>
              <CCol sm="4"></CCol>
            </CRow>
            <CRow class="home-section-one">
              <CCol sm="4"></CCol>
              <CCol sm="4" class="home-subtitle" :style='"color: "+raffle.color_2'>
                <h3>Cuentas de Pago<br />México</h3>   
              </CCol>
              <CCol sm="4"></CCol>
            </CRow>
            <CRow class="home-section-one">
              <CCol sm="4"></CCol>
              <CCol sm="4" class="home-subtitle">
                <hr />
                <table style="width: 100%;">
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Banco: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">Banorte</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Nombre: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">Roberto Ochoa Luna</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Clabe: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">072326012701245958</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Tarjeta: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">4189 2810 1064 9409</td>
                  </tr>
                </table>
                <hr />
                <table style="width: 100%;">
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Banco: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">HSBC</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Nombre: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">Roberto Ochoa Luna</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Clabe: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">021326040679839574</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Tarjeta: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">4741 7640 0164 8564</td>
                  </tr>
                </table>
                <hr />
                <table style="width: 100%;">
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Banco: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">Azteca</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Nombre: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">Roberto Ochoa Luna</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Clabe: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">127326013065265670</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Tarjeta: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">5512 3824 1595 6475</td>
                  </tr>
                </table>
                <hr />
                <table style="width: 100%;">
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Banco: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">Scotiabank</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Nombre: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">Roberto Ochoa Luna</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Clabe: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">044326256052715400</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Tarjeta: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">4043 1300 2185 4167</td>
                  </tr>
                </table>
                <hr />
                <table style="width: 100%;">
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Banco: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">Bancoppel</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Nombre: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">Roberto Ochoa Luna</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Clabe: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">137326105012579453</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Tarjeta: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">4169 1614 4408 9816</td>
                  </tr>
                </table>
                <hr />          
              </CCol>
              <CCol sm="4"></CCol>
            </CRow>
            <CRow class="home-section-one">
              <CCol sm="4"></CCol>
              <CCol sm="4" class="home-subtitle" :style='"color: "+raffle.color_2'>
                <h3>Cuentas de Pago<br />Estados Unidos</h3>              
              </CCol>
              <CCol sm="4"></CCol>
            </CRow>
            <CRow class="home-section-one">
              <CCol sm="4"></CCol>
              <CCol sm="4" class="home-subtitle">
                <hr />
                <table style="width: 100%;">
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Banco: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">Azteca</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Nombre: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">Roberto Ochoa Luna</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Clabe: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">127326013065265670</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Tarjeta: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">5512 3824 1595 6475</td>
                  </tr>
                </table>
                <hr />
                <table style="width: 100%;">
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Banco: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">Bancoppel</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Nombre: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">Roberto Ochoa Luna</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Clabe: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">137326105012579453</td>
                  </tr>
                  <tr>
                    <td style="width: 40%; text-align: left; padding: 5px;">Tarjeta: </td>
                    <td style="width: 60%; text-align: left; padding: 5px;">4169 1614 4408 9816</td>
                  </tr>
                </table>
                <hr />          
              </CCol>
              <CCol sm="4"></CCol>
            </CRow>      
          </CContainer>
        </main>
      </div>
      <TheFooter :c1="raffle.color_1" :c2="raffle.color_2" @anchor="anchor"/>
    </CWrapper>
    <loading :active="visible" :can-cancel="true"></loading>
  </div>
</template>

<script>
import TheHeader from '../../containers/TheHeaderLanding'
import TheFooter from '../../containers/TheFooterLanding'
import Sections from '../../components/application/Sections'
import Vue from 'vue';
import Loading from 'vue-loading-overlay/dist/vue-loading.js';
import 'vue-loading-overlay/dist/vue-loading.css';
import ws from '../../services/raffles';
import store from '../../store'

export default {
  name: 'Payments',
  components: {
    TheHeader,
    TheFooter,
    Loading,
    Sections
  },
  data() {
      return {
          has_raffles: true,
          no_raffles_label: "",
          raffle: false,
          color_1: false,
          color_2: false
      }
  },
  mounted: async function() {
    this.loading();
      this.code = await this.$route.params.code;

      let response = false;
      if(this.code === undefined){
        response = await ws.getMainRaffle();  
      }
      else{
        response = await ws.getRaffle(this.code);  
      }

      if(response.type == "success"){
          this.raffle = response.data;
      }
      else{
          this.no_raffles_label = response.message;
          this.has_raffles = false;
      }
    this.loaded();
  }, 
  computed: {
    toasts () {
      return store.state.toasts;
    },
    visible() {
      return store.state.isLoading;
    }
  },
  methods: {
    anchor(anchor_id){
      let anchor = false;

      switch(anchor_id){
        case 'nosotros': case 'preguntas': case 'contacto': case 'garantia': case 'inicio': {
          this.$router.push({path: (this.code !== undefined ? '/'+this.code : '')+'/', query: {anchor: anchor_id}});
          return;
        }
        case 'tickets': {
          this.$router.push({path: (this.code !== undefined ? '/'+this.code : '')+'/boletos'})
          return;
        }
        case 'terms': {
          this.$router.push({path: (this.code !== undefined ? '/'+this.code : '')+'/aviso-de-privacidad'})
          return;
        }
      }
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
<style scoped>
.home-title{
    display: flex;
    width: 100%;
    color: #fff;
    height: 80px;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 54px;
    text-shadow: 1px 3px 5px #000;
    align-items: center;
    justify-content: center;
}
h1, h3{
  font-weight: bolder;
}
.home-subtitle{
  text-align: center;
  margin-top: 10px;
  text-shadow: 1px 3px 5px #AAA;
  font-family: "Lato", sans-serif;
}
.home-subtitle a{
  text-align: center !important;
  margin-top: 10px !important;
  text-shadow: 1px 3px 5px #AAA !important;
  font-family: "Lato", sans-serif !important;
}
.home-icon a{
  display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    line-height: normal;
    height: auto;
    min-height: 20px;
    min-width: 20px;
    overflow: hidden;
    margin: 0 5px 0 -1px;
    padding: 0 24px;
    cursor: pointer;
    pointer-events: auto;
}
.c-body{
  font-family: "Lato", sans-serif !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.row{
  margin:0px;
}
</style>
